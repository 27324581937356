<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Perfil Usuario</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Perfil Usuario</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-3">
                <!-- Profile Image -->
                <div class="card card-primary card-outline">
                  <div class="card-body box-profile">
                    <div class="text-center">
                      <img
                        v-if="user.link_photography === null"
                        class="profile-user-img img-fluid img-circle"
                        src="/img/perfil_vacio.png"
                        style="height: 120px"
                        alt="User profile picture"
                      />

                      <img
                        v-else
                        class="profile-user-img img-fluid img-circle"
                        :src="uri_docs + user.link_photography"
                        style="height: 150px"
                        alt="User profile picture"
                      />
                    </div>
                    <h3 class="profile-username text-center">
                      <b>{{ this.user.name }}</b>
                    </h3>
                    <p class="text-muted text-center">
                      {{ this.user.charge }}
                    </p>
                    <button
                      type="button"
                      class="btn btn-primary btn-block"
                      v-if="cambiarFoto === 1"
                      @click="cambiarEstadoFoto()"
                    >
                      <b>Cambiar foto</b>
                    </button>
                    <div v-if="cambiarFoto == 2">
                      <div v-if="file === null">
                        <input
                          type="file"
                          id="file"
                          ref="file"
                          class="form-control-file"
                          accept=".jpg, .JPG, .png, .PNG"
                          @change="elegirDirectorio()"
                        />
                      </div>
                      <div v-else>
                        <button
                          type="button"
                          @click="saveImage()"
                          class="btn btn-success col-md-4"
                          data-toggle="tooltip"
                          data-html="true"
                          title="Guardar Imagen"
                        >
                          <i class="fa fa-upload"></i>
                        </button>
                        <button
                          type="button"
                          @click="destroyImage()"
                          class="btn btn-secondary col-md-4"
                          data-toggle="tooltip"
                          data-html="true"
                          title="Eliminar Imagen"
                        >
                          <i class="fas fa-eraser"></i>
                        </button>
                        <button
                          type="button"
                          @click="cancelarCambio()"
                          class="btn btn-danger col-md-4"
                          data-toggle="tooltip"
                          data-html="true"
                          title="Cancelar Cambio"
                        >
                          <i class="fas fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- /.card-body -->
                </div>
                <!-- /.card -->

                <!-- /.card -->
              </div>
              <!-- /.col -->
              <div class="col-md-9">
                <div class="card">
                  <div class="card-header p-2">
                    <ul class="nav nav-pills">
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="#cuenta"
                          data-toggle="tab"
                          @click="activeButton(1)"
                          >Cuenta</a
                        >
                      </li>
                      <li
                        class="nav-item"
                      >
                        <a
                          class="nav-link"
                          href="#ausencia"
                          data-toggle="tab"
                          @click="activeButton(0)"
                          >Ausencias</a
                        >
                      </li>
                      <button
                        class="btn bg-navy ml-1"
                        v-show="!$v.user.$invalid && cuenta == 1"
                        @click="save()"
                      >
                        <i class="fas fa-check"></i>
                      </button>
                    </ul>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body">
                    <div class="tab-content">
                      <div class="tab-pane" id="cuenta">
                        <div class="form-group row">
                          <label for="nombre" class="col-sm-2 col-form-label"
                            >Nombre</label
                          >
                          <div class="col-sm-10">
                            <input
                            :disabled="!($store.getters.can('admin.usuarios.edit'))"
                              v-model="user.name"
                              type="text"
                              class="form-control"
                              id="nombre"
                              placeholder="Nombre"
                              :class="
                                $v.user.name.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="email" class="col-sm-2 col-form-label"
                            >Correo electronico</label
                          >
                          <div class="col-sm-10">
                            <input
                              type="email"
                              v-model="user.email"
                              class="form-control"
                              id="email"
                              placeholder="Email"
                              disabled="disabled"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            for="tipo_documento"
                            class="col-sm-2 col-form-label"
                            >Tipo_documento</label
                          >
                          <div class="col-sm-10">
                            <select
                              class="form-control"
                              v-model="user.type_document"
                              :class="
                                $v.user.type_document.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            :disabled="!($store.getters.can('admin.usuarios.edit'))"
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="documento in listasForms.tipo_documentos"
                                :key="documento.numeracion"
                                :value="documento.numeracion"
                              >
                                {{ documento.descripcion }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            for="numero_documento"
                            class="col-sm-2 col-form-label"
                            >Número de documento</label
                          >
                          <div class="col-sm-10">
                            <input
                            :disabled="!($store.getters.can('admin.usuarios.edit'))"
                              type="text"
                              v-model="user.n_document"
                              class="form-control"
                              id="numero_documento"
                              placeholder="Numero documento"
                              :class="
                                $v.user.n_document.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="celular" class="col-sm-2 col-form-label"
                            >Celular</label
                          >
                          <div class="col-sm-10">
                            <input
                            :disabled="!($store.getters.can('admin.usuarios.edit'))"
                              type="text"
                              v-model="user.cel"
                              class="form-control"
                              id="celular"
                              placeholder="celular"
                              :class="
                                $v.user.cel.$invalid ? 'is-invalid' : 'is-valid'
                              "
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            for="numero_documento"
                            class="col-sm-2 col-form-label"
                            >Cargo</label
                          >
                          <div class="col-sm-10">
                            <input
                              type="text"
                              v-model="user.charge"
                              class="form-control"
                              id="cargo"
                              placeholder="Cargo"
                              :class="
                                $v.user.charge.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                        </div>
                        <hr />
                        <div class="form-group row">
                          <div class="offset-sm-2 col-sm-10">
                            <button
                              type="button"
                              class="btn btn-success"
                              v-if="accion === 0 && user.link_firma == null"
                              @click="cambiarEstadoFirma()"
                            >
                              Crear firma
                            </button>

                            <button
                              type="button"
                              class="btn btn-success"
                              v-if="accion === 0 && user.link_firma != null"
                              @click="cambiarEstadoFirma()"
                            >
                              Cambiar firma
                            </button>
                            <div v-if="accion === 0">
                              <br />
                            </div>

                            <img
                              v-if="user.link_firma != null && accion === 0"
                              :src="uri_docs + user.link_firma"
                              style="height: 150px; width: 300px"
                              alt="Firma de usuario"
                            />

                            <br />
                            <div v-if="accion === 1">
                              <label for="firma">Seleccione la firma:</label>
                              <input
                                type="file"
                                id="file"
                                ref="firma"
                                class="form-control-file"
                                accept=".jpg, .JPG, .png, .PNG"
                                @change="elegirDirectorio2()"
                              />
                              <br />
                              <label for="password">Contraseña</label>
                              <input
                                type="password"
                                v-model="form.password"
                                class="form-control"
                                id="password"
                                placeholder="Contraseña"
                                :class="
                                  $v.form.password.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                              <p
                                class="bg-danger"
                                v-if="!$v.form.password.required"
                              >
                                La contraseña es requerida para el cambio o
                                creación de una firma.
                              </p>
                              <br />

                              <button
                                type="button"
                                class="btn btn-primary"
                                @click="guardarFirma()"
                                v-if="!$v.form.$invalid && firma !== null"
                              >
                                Guardar
                              </button>

                              <button
                                type="button"
                                class="btn btn-danger"
                                @click="limpiarFirma()"
                              >
                                Cancelar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane"
                        id="ausencia"
                        
                      >
                        <div class="row">
                          <div class="form-group col-md-3">
                            <label for="fecha_inicio">Fecha Inicio</label>
                            <input
                              type="date"
                              id="fecha_inicio"
                              class="form-control form-control-sm"
                              v-model="form_ausencia.fecha_inicio"
                              :class="
                                $v.form_ausencia.fecha_inicio.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              @input="validaFechas"
                            />
                          </div>
                          <div class="form-group col-md-3">
                            <label for="fecha_fin">Fecha Fin</label>
                            <input
                              type="date"
                              id="fecha_fin"
                              class="form-control form-control-sm"
                              v-model="form_ausencia.fecha_fin"
                              :class="
                                $v.form_ausencia.fecha_fin.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              @input="validaFechas"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label for="reemplazo_id">Remplazo</label>
                            <v-select
                              :class="[
                                $v.form_ausencia.reemplazo_id.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-model="reemplazos"
                              placeholder="Funcionario"
                              label="funcionario"
                              class="form-control form-control-sm p-0"
                              :options="listasForms.reemplazos"
                              @input="asignar_reemplazo"
                            >
                            </v-select>
                          </div>
                          <div class="form-group col-md-12">
                            <label for="motivo">Motivo</label>
                            <textarea
                              class="form-control form-control-sm"
                              cols="30"
                              v-model="form_ausencia.motivo"
                              :class="
                                $v.form_ausencia.motivo.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            ></textarea>
                          </div>
                        </div>
                        <div class="row" v-if="!$v.form_ausencia.$invalid">
                          <div class="form-group col-md-12 text-right">
                            <button
                              type="button"
                              class="btn btn-primary"
                              @click="saveAusencia"
                            >
                              <b>Agregar</b>
                            </button>
                          </div>
                        </div>
                        <!-- Lisado histórico de ausencias -->
                        <div class="row">
                          <div class="form-group col-md-12">
                            <div class="hold-transition">
                              <div id="accordion">
                                <div class="card card-gray-dark mb-1">
                                  <div class="card-header">
                                    <a
                                      data-toggle="collapse"
                                      href="#collapse"
                                      aria-expanded="false"
                                      class="d-block w-100 collapsed"
                                      ><h4 class="card-title w-100">
                                        <i class="fas fa-history mr-2"></i>
                                        Histórico de Ausencias
                                      </h4></a
                                    >
                                  </div>
                                  <div
                                    id="collapse"
                                    data-parent="#accordion"
                                    class="collapse"
                                    style=""
                                  >
                                    <div class="card-body">
                                      <div class="row">
                                        <table
                                          class="
                                            table
                                            table-striped
                                            table-bordered
                                            table-hover
                                            table-sm
                                          "
                                        >
                                          <thead class="thead-dark">
                                            <tr>
                                              <th class="text-nowrap">
                                                Fecha Inicio
                                              </th>
                                              <th class="text-nowrap">
                                                Fecha Fin
                                              </th>
                                              <th class="text-nowrap">
                                                Motivo
                                              </th>
                                              <th class="text-nowrap">
                                                Reemplazo
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              v-for="aus in ausencias"
                                              :key="aus.id"
                                            >
                                              <td
                                                class="text-center text-nowrap"
                                              >
                                                {{ aus.fecha_inicio }}
                                              </td>
                                              <td
                                                class="text-center text-nowrap"
                                              >
                                                {{ aus.fecha_fin }}
                                              </td>
                                              <td class="text-wrap">
                                                <small>{{ aus.motivo }}</small>
                                              </td>
                                              <td class="text-nowrap">
                                                {{ aus.reemplazo.nombres }}
                                                {{ aus.reemplazo.apellidos }}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                id="Modal_tarifas"
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                                class="modal fade"
                              >
                                <div
                                  role="document"
                                  class="modal-dialog modal-lg"
                                >
                                  <div class="modal-content">
                                    <div
                                      class="
                                        modal-header
                                        bg-frontera-top-left
                                        pt-2
                                        pb-2
                                      "
                                    >
                                      <h5
                                        id="exampleModalLabel"
                                        class="modal-title text-white"
                                      >
                                        Actualizar Tarifa
                                      </h5>
                                      <button
                                        type="button"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        class="close"
                                      >
                                        <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div class="modal-body">
                                      <div class="row">
                                        <div class="col-md-4">
                                          <div class="form-group">
                                            <label for="radicado"
                                              >Radicado RNDC</label
                                            ><input
                                              type="text"
                                              id="radicado"
                                              class="
                                                form-control form-control-sm
                                              "
                                            />
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="form-group">
                                            <label for="tarifa">Tarifa</label
                                            ><input
                                              type="number"
                                              id="tarifa"
                                              class="
                                                form-control form-control-sm
                                                is-invalid
                                              "
                                            />
                                          </div>
                                        </div>
                                        <div class="col-md-2">
                                          <div class="form-group">
                                            <label for="tiempo_cargue_sb"
                                              >Tiempo de cargue</label
                                            ><input
                                              type="number"
                                              id="tiempo_cargue_sb"
                                              class="
                                                form-control form-control-sm
                                                is-invalid
                                              "
                                            />
                                          </div>
                                        </div>
                                        <div class="col-md-2">
                                          <div class="form-group">
                                            <label for="tiempo_descargue_sb"
                                              >Tiempo descargue</label
                                            ><input
                                              type="number"
                                              id="tiempo_descargue_sb"
                                              class="
                                                form-control form-control-sm
                                                is-invalid
                                              "
                                            />
                                          </div>
                                        </div>
                                        <div class="col-md-3">
                                          <div class="form-group">
                                            <label for="fecha_inicio_vigencia"
                                              >Fecha inicio vigencia</label
                                            ><input
                                              type="date"
                                              id="fecha_inicio_vigencia"
                                              class="
                                                form-control form-control-sm
                                                is-invalid
                                              "
                                            />
                                          </div>
                                        </div>
                                        <div class="col-md-3">
                                          <div class="form-group">
                                            <label for="fecha_fin_vigencia"
                                              >Fecha fin vigencia</label
                                            ><input
                                              type="date"
                                              id="fecha_fin_vigencia"
                                              class="
                                                form-control form-control-sm
                                                is-invalid
                                              "
                                            />
                                          </div>
                                        </div>
                                        <div class="form-group col-md-6">
                                          <label>Adjunto</label
                                          ><input
                                            type="file"
                                            id="archivo-adjunto-tarifario"
                                            accept="image/x-png,image/gif,image/jpeg,application/pdf,"
                                            class="form-control-file"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="
                                        modal-footer
                                        justify-content-between
                                      "
                                    >
                                      <button
                                        type="button"
                                        data-dismiss="modal"
                                        class="btn btn-secondary"
                                      >
                                        Cerrar</button
                                      ><button
                                        type="button"
                                        class="btn btn-primary"
                                        style="display: none"
                                      >
                                        Guardar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- /.tab-pane -->
                    </div>
                    <!-- /.tab-content -->
                  </div>
                  <!-- /.card-body -->
                </div>
                <!-- /.card -->
              </div>
              <!-- /.col -->
            </div>
            <!-- /.row -->
          </div>
          <!-- /.container-fluid -->
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../components/Loading";

import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "PerfilUsuario",
  components: {
    vSelect,
    Loading,
  },
  data() {
    return {
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      cargando: false,
      file: null,
      firma: null,
      accion: 0,
      ausencias: [],
      reemplazos: [],
      form: {
        password: null,
      },
      form_ausencia: {
        funcionario_id: null,
        fecha_inicio: null,
        fecha_fin: null,
        reemplazo_id: null,
        motivo: null,
      },
      cambiarFoto: 1,
      user: {
        funcionario: {},
      },
      listasForms: {
        tipo_documentos: [],
        reemplazos: [],
      },
      cuenta: 0,
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(12),
        maxLength: maxLength(50),
      },
    },

    user: {
      name: {
        required,
        minLength: minLength(10),
      },
      type_document: {
        required,
      },
      n_document: {
        required,
        minLength: minLength(5),
      },
      cel: {
        required,
        minLength: minLength(10),
      },
      charge: {
        required,
        minLength: minLength(8),
      },
    },
    form_ausencia: {
      funcionario_id: {
        required,
      },
      fecha_inicio: {
        required,
      },
      fecha_fin: {
        required,
      },
      reemplazo_id: {
        required,
      },
      motivo: {
        required,
        minLength: minLength(10),
      },
    },
  },

  methods: {
    init() {
      this.cargando = true;
      axios.get("/api/user").then((response) => {
        this.user = response.data;
        this.llenar_ausencia();
        this.cargando = false;
      });
    },

    getTipoDocumentos() {
      axios.get("/api/lista/6").then((response) => {
        this.listasForms.tipo_documentos = response.data;
      });
    },

    elegirDirectorio() {
      this.file = this.$refs.file.files[0];
    },

    elegirDirectorio2() {
      this.firma = this.$refs.firma.files[0];
    },

    cambiarEstadoFoto() {
      if (this.cambiarFoto == 1) {
        if (this.user.link_photography !== null) {
          this.cambiarFoto = 2;
        } else {
          this.cambiarFoto = 2;
        }
      }
    },

    activeButton(est) {
      this.cuenta = est;
    },

    save() {
      let form1 = {
        id: this.user.id,
        name: this.user.name,
        type_document: this.user.type_document,
        n_document: this.user.n_document,
        cel: this.user.cel,
        charge: this.user.charge,
      };

      if (!this.$v.user.$invalid) {
        axios({
          method: "PUT",
          url: "/api/admin/usuarios",
          data: form1,
        })
          .then((response) => {
            this.$swal({
              icon: "success",
              title:
                "Se realizo la actualizacion de datos del usuario correctamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        this.init();
      }
    },

    saveImage() {
      var vm = this;
      let formData = new FormData();
      formData.append("imagen", vm.file);
      formData.append("user_id", vm.user.id);
      axios
        .post("api/admin/usuarios/saveImagen", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.init();
          this.$swal({
            icon: "success",
            title: "Se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    destroyImage() {
      this.file = null;
    },

    cancelarCambio() {
      this.file = null;
      this.cambiarFoto = 1;
    },

    cambiarEstadoFirma() {
      this.accion = 1;
    },

    limpiarFirma() {
      this.accion = 0;
      this.firma = null;
      this.form.password = null;
    },

    guardarFirma() {
      var vm = this;

      let formData = new FormData();
      formData.append("firma", vm.firma);
      formData.append("user_id", vm.user.id);
      formData.append("password", vm.form.password);
      axios
        .post("api/admin/usuarios/saveFirma", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.coincidir) {
            this.form.password = null;
            this.$swal({
              icon: "error",
              title: response.data.coincidir,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            this.init();
            this.limpiarFirma();
            this.$swal({
              icon: "success",
              title: "Se guardó exitosamente la firma",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    llenar_ausencia() {
      if (this.user.funcionario.length > 0) {
        this.form_ausencia.funcionario_id = this.user.funcionario[0].id;
        this.ausencias = this.user.funcionario[0].ausencias;
        this.getReemplazos();
      }
    },

    getReemplazos() {
      const params = {
        not_id: [this.form_ausencia.funcionario_id],
      };
      axios
        .get("/api/admin/funcionarios/list", {
          params,
        })
        .then((response) => {
          this.listasForms.reemplazos = response.data;
        });
    },

    asignar_reemplazo() {
      this.form_ausencia.reemplazo_id = null;
      if (this.reemplazos) {
        this.form_ausencia.reemplazo_id = this.reemplazos.id;
      }
    },

    validaFechas() {
      if (this.form_ausencia.fecha_inicio && this.form_ausencia.fecha_fin) {
        const fecha_menor = new Date(this.form_ausencia.fecha_inicio);
        const fecha_mayor = new Date(this.form_ausencia.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form_ausencia.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha de inicio no puede ser mayor a la fecha fin...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    saveAusencia() {
      this.cargando = true;
      axios({
        method: "PUT",
        url: "/api/admin/funcionarios/ausencia",
        data: this.form_ausencia,
      }).then((response) => {
        this.init();
        this.reemplazos = [];
        this.form_ausencia = {
          funcionario_id: this.user.funcionario[0].id,
          fecha_inicio: null,
          fecha_fin: null,
          reemplazo_id: null,
          motivo: null,
        };
        this.$swal({
          icon: "success",
          title: "Se guardo exitosamente...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.cargando = false;
      });
    },
  },

  mounted() {
    this.init();
    this.getTipoDocumentos();
  },
};
</script>
